<template>
<div>
    <div class="font-4x fw-bold" v-if="responsive == 'PC'">
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="6">
                <span class="d-flex flex-row-reverse mr-2" :class="totalRoe == 0 ? '' : (totalRoe > 0 ? 'green--text' : 'red--text')">
                    {{ totalRoe == 0 ? '' : (totalRoe > 0 ? ('+' + totalRoe.toFixed(2) + '%') : (totalRoe.toFixed(2) + '%')) }}
                </span>
            </v-col>
        </v-row>
    </div>
    <div class="mb-3 font-3x fw-bold" v-if="responsive == 'mobile' || responsive == 'pad'">
        <div :class="totalRoe == 0 ? '' : (totalRoe > 0 ? 'green--text' : 'red--text')">
            {{ totalRoe == 0 ? '' : (totalRoe > 0 ? ('+' + totalRoe.toFixed(2) + '%') : (totalRoe.toFixed(2) + '%')) }}
        </div>
    </div>
    <div class="mb-12"></div>
    <div style="height: 360px" :id="id" v-if="responsive == 'PC'"></div>
    <div style="height: 290px" :id="id" v-if="responsive == 'mobile' || responsive == 'pad'"></div>
</div>
</template>

<style>
svg.highcharts-root {
    font-family: "Noto Sans SC", sans-serif !important;
}

.test tspan:active {
    text: expression(target="_blank");
}
</style>

<script>
import config from '@/config.js';
import Highcharts from 'highcharts/highstock';
import Bus from '@/components/bus/Bus';

import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            historyItems:[],
            recentItems:[],
            id: 'portfolioRoeLine',
            chart: null,
            loading: false,
            option: {
                // 图表类型
                chart: {
                    backgroundColor: this.darkMode ? 'dark' : '', 
                    renderTo: 'portfolioRoeLine',
                    type: 'line',
                    // x方向和y方向都可以用鼠标拖拽放大
                    zoomType: 'xy'
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#9E9E9E'
                    },
                },
                subtitle: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#999999'
                    },
                },
                // x轴
                xAxis: {
                    categories: [],
                    lineWidth: 1,
                    tickPosition: 'inside',
                    type: 'category',
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S',
                        second: '%m/%d/%Y %H:%M:%S',
                        minute: '%m/%d/%Y %H:%M',
                        hour: '%m/%d/%Y %H:%M',
                        day: '%m/%d',
                        week: '%m/%d',
                        month: '%m/%Y',
                        year: '%Y'
                    },
                    labels: {
                        enabled: true
                    }
                },
                // y轴
                yAxis: {
                    title: {
                        text: null
                    },
                    visible: true,
                    // 横线宽度设置为0表示不显示横线
                    gridLineWidth: 0,
                    opposite: true,
                    offset: 30,
                    labels: {
                        align: "right",
                        x: -3,
                        formatter: function () {
                            return this.value.toFixed(0) + "%";
                        }
                    },
                },
                // 鼠标悬浮提示框
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: '%',
                    followTouchMove: true,
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S UTC',
                        second: '%m/%d/%Y %H:%M:%S UTC',
                        minute: '%m/%d/%Y %H:%M UTC',
                        hour: '%m/%d/%Y %H:%M UTC',
                        day: '%m/%d/%Y %H:%M UTC',
                        week: '%m/%d %H:%M UTC',
                        month: '%m/%Y',
                        year: '%Y'
                    }
                },
                // 数据
                series: [{
                    // type: 'spline',
                    name: "RoE",
                    data: [],
                    color: {
                                linearGradient: {
                                  x1: 0,
                                  x2: 0,
                                  y1: 0,
                                  y2: 1
                                },
                                stops: [
                                  [0, '#4CAF50'],
                                  [1, '#FEE108']
                                ]
                              },
                    lineWidth: 3
                }],
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                // 图例导航
                legend: {
                    enabled: false,
                    align: 'left',
                    itemStyle: {
                        color: '#999999'
                    },
                    itemHoverStyle: {
                        color: '#999999'
                    },
                    itemHiddenStyle: {
                        color: '#606063'
                    },
                    title: {
                        style: {
                            color: '#C0C0C0',
                            fontFamily: '\'Noto Sans SC\', sans-serif'
                        }
                    }
                },
                navigation: {
                    buttonOptions: {
                        symbolStroke: 'black',
                        theme: {
                            fill: '#EEEEEE',
                        },
                    }
                },
                // 图形参数
                plotOptions: {
                    line: {
                        lineWidth: 2,
                        cursor: 'pointer',
                        marker: {
                            enabled: false
                        }
                    }
                },
                // 版权信息
                credits: {
                    enabled: false,
                    href: config.fund,
                    text: 'View on iobots.pro',
                    className: 'a',
                    position: {
                        x: -12,
                        y: -5
                    },
                    style: {
                        fontSize: '12px',
                        color: 'white',
                        target: '_black'
                    }
                },
                exporting: {
                    enabled: true,
                    filename: 'Model-Portfolio-Performance',
                    buttons: {
                        contextButton: {
                            align: "left",
                            verticalAlign: "top",
                            x: -10,
                            y: 0
                        }
                    }
                },
                loading: {
                    style: {
                        backgroundColor: 'silver'
                    },
                    labelStyle: {
                        color: 'white',
                        fontSize: '36px'
                    }
                }
            },
        }
    },
    props: {

    },
    watch: {
        // 监听加载中
        loading(newVal) {
            if (this.chart) {
                if (newVal) {
                    this.chart.showLoading();
                } else {
                    this.chart.hideLoading();
                }
            }
        },
        historyItems(val){
            if(val.length > 0){
                this.getLine(this.type)
            }
        },
        recentItems(val){
            if(val.length > 0){
                this.getLine(this.type)
            }
        },
        type(val){
            this.loading = true;
            this.getLine(val);
        },
    },
    created() {
        if(this.responsive == 'mobile'){
            this.option.xAxis.labels.enabled = false;
        }
    },
    mounted() {
        Highcharts.setOptions({
            lang: {
                noData: "",
                contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                printChart: this.$t('highcharts.printChart'),
                resetZoom: this.$t('highcharts.resetZoom'),
                resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                downloadPNG: this.$t('highcharts.downloadPNG'),
                downloadJPEG: this.$t('highcharts.downloadJPEG'),
                downloadPDF: this.$t('highcharts.downloadPDF'),
                downloadSVG: this.$t('highcharts.downloadSVG')
            }
        });
        this.option.credits.style.fontSize = this.responsive == 'mobile' ? '10px' : '12px';
        this.renderChart();
        Bus.$on('portfolioRoeHistory', data => {
            this.historyItems = data;
        });
        Bus.$on('portfolioRoeRecent', data => {
            this.recentItems = data;
        });
        this.getLine(this.type);
    },
    computed: {
        ...mapGetters(['responsive','darkMode']),
        totalRoe(){
            let roe = this.option.series[0].data.length > 0 ? this.option.series[0].data[this.option.series[0].data.length - 1] : 0;
            return roe;
        }
    },
    components: {
    },
    props:['type'],
    methods: {
        getLine(val){
            this.option.series[0].data = [];
            this.option.xAxis.categories = [];
            if(val == "history" && this.historyItems.length > 0){
                this.option.series[0].data = this.historyItems;
                this.option.xAxis.categories = ["30 Days","60 Days","1 Quarter","6 Months","1 Year","Since Inception"];
                this.renderChart();
            }
            if(val == "recent" && this.recentItems.length > 0){
                this.option.series[0].data = this.recentItems;
                this.option.xAxis.categories = ["Last 30 Days","Last 60 Days","Last 1 Quarter","Last 6 Months","Last 1 Year","Since Inception"];
                this.renderChart();
            }
        },
        // 渲染图表
        renderChart() {
            this.chart = Highcharts.chart(this.id, this.option);
            this.loading = false;
        },
        noData() {
            Highcharts.setOptions({
                lang: {
                    noData: this.$t('highcharts.noData'),
                    contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                    printChart: this.$t('highcharts.printChart'),
                    resetZoom: this.$t('highcharts.resetZoom'),
                    resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                    downloadPNG: this.$t('highcharts.downloadPNG'),
                    downloadJPEG: this.$t('highcharts.downloadJPEG'),
                    downloadPDF: this.$t('highcharts.downloadPDF'),
                    downloadSVG: this.$t('highcharts.downloadSVG')
                }
            });
            this.renderChart();
        },
    }
}
</script>
