<template>
    <div>
        <v-container fluid class="grid-list-xl pt-0 mt-12" v-if="responsive == 'PC'">
            <v-row class="align-center justify-center">
                <v-col cols="12" md="9" class="px-0 mt-3">
                    <v-card flat tile class="pt-5">
                        <h3 class="text-center">{{ $t('portfolio.modelPortfolioPerformance') }}</h3>
                        <v-col cols="12" class="px-0 mt-5 mx-5">
                            <v-row class="mx-5">
                                <v-col cols="12" sm="12" md="12" class="pl-0 pr-11">
                                    <portfolio-roe-line :type="type"></portfolio-roe-line>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-tabs class="mt-5" v-model="type" align-with-title :slider-size="5" slider-color="lightred">
                            <v-tab href="#history">{{ $t('portfolio.history') }}</v-tab>
                            <v-tab href="#recent">{{ $t('portfolio.recent') }}</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="type" class="mt-5">
                            <v-tab-item value="history">
                                <portfolio-result></portfolio-result>
                            </v-tab-item>
                            <v-tab-item value="recent">
                                <portfolio-last></portfolio-last>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid class="flex-b grid-list-xl mt-12 pb-0 px-0 pt-0" v-if="responsive == 'mobile'">
            <signal-mobile-change></signal-mobile-change>
            <v-row class="align-center justify-center mx-4">
                <v-col cols="12" md="12" class="px-0 mt-3">
                    <v-card flat tile class="pt-5">
                        <h3 class="text-center">{{ $t('portfolio.modelPortfolioPerformance') }}</h3>
                        <v-col cols="12" class="mt-5">
                            <v-row class="mx-5">
                                <v-col cols="12" sm="12" md="12" class="pl-0">
                                    <portfolio-roe-line :type="type"></portfolio-roe-line>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-tabs class="mt-5" v-model="type" align-with-title :slider-size="5" slider-color="lightred">
                            <v-tab href="#history">{{ $t('portfolio.history') }}</v-tab>
                            <v-tab href="#recent">{{ $t('portfolio.recent') }}</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="type">
                            <v-tab-item value="history">
                                <portfolio-result></portfolio-result>
                            </v-tab-item>
                            <v-tab-item value="recent">
                                <portfolio-last></portfolio-last>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- <mobile-btn></mobile-btn> -->
    </div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import PortfolioLast from '@/components/signal/PortfolioLast';
    import PortfolioResult from '@/components/signal/PortfolioResult';
    import MobileBtn from "@/components/common/MobileBtn";
    import PortfolioRoeLine from "@/highcharts/PortfolioRoeLine";
    export default {
        data(){
            return{
                type:"result",
                all:true,

            }
        },
        created(){
            // if(this.user.token){
            //     this.$store.dispatch("signalHeaderTitleHandler", 'modelPortfolio');
            //     this.$store.dispatch("signalPageHandler", this.$t("menu.modelPortfolio"));
            //     this.$store.dispatch("mobileBtnPageHandler",1);
            // }else{
            //     this.isLogin();
            // }
            this.$store.dispatch("signalHeaderTitleHandler", 'modelPortfolio');
            this.$store.dispatch("signalPageHandler", this.$t("menu.modelPortfolio"));
            this.$store.dispatch("mobileBtnPageHandler",1);
        },
        mounted(){
        },
        components:{
            PortfolioLast,
            PortfolioResult,
            MobileBtn,
            PortfolioRoeLine
        },
       computed: {
            ...mapGetters(['responsive','darkMode','user']),
        },
        watch:{

        },
        methods: {
            // 是否登录
            isLogin() {
                if (!this.user.token) {
                this.$router.push({ name: "signIn" });
                this.$store.dispatch("snackbarMessageHandler", this.$t("signals.signalSnackbarMessage"));
                }
            },
        },
    }
</script>
