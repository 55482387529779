<template>
  <div>
    <v-row v-if="loading" dense class="pa-12">
      <v-col cols="12">
        <v-skeleton-loader type="table-heading, table-thead, table-row-divider@7" class="mx-auto"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!loading" dense class="pa-12 pt-3">
      <!-- <v-col cols="12" md="6" xs="2" class="mt-3">
        <span>{{ $t('portfolio.startingDate') }}: 07/17/2019</span>
      </v-col> -->
      <v-col cols="12" md="6" xs="2" class="mt-3">
        <span>{{ $t('portfolio.date') }}: 07/17/2019 - 10/16/2020</span>
      </v-col>
      <v-col cols="12" md="2" xs="2" class="ml-auto">
        <v-select :items="selectList" class="fs-14" item-text="text" item-value="value" clearable v-model="current"
          hide-details dense :label="$t('portfolio.interval')"></v-select>
      </v-col>
      <v-col cols="12" md="2" xs="2" class="ml-auto" v-if="responsive =='mobile'">
        <v-select :items="symbolItems" class="fs-14"  v-model="symbol"hide-details dense :label="$t('portfolio.currency')"></v-select>
      </v-col>
      <!-- 30days -->
      <v-col cols="12" class="mt-5" v-if="current == null || current == '30days'">
        <h5 class="text-center">{{$t("portfolio.30days")}}</h5>
        <v-data-table dense :headers="Headers" :items="responsive == 'PC' ? portfolioResult.day30 : item30" hide-default-footer disable-pagination>
          <template v-slot:item.currency="{ item }">
            {{item.symbol}}
          </template>
          <template v-slot:item.sValue="{ item }">
            ${{formatNum(item.starting.toFixed(2))}}
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</span>
          </template>
          <template v-slot:item.eValue="{ item }">
            <span class="font-12 text-right">${{formatNum(item.ending.toFixed(2))}}</span>
          </template>
          <template v-slot:body.append v-if="responsive =='PC'">
            <tr>
              <td colspan="5"></td>
            </tr>
            <tr class="fw-bold">
              <td class="font-12">{{$t("portfolio.total")}}</td>
              <td class="font-12 text-right">${{formatNum(starting30)}}</td>
              <td class="font-12 text-right">{{starting30 == 0 ? "0.00" : ((ending30 - starting30) / starting30 * 100).toFixed(2)}}%</td>
              <td class="font-12 text-right">${{formatNum(pl30)}}</td>
              <td class="font-12 text-right">${{formatNum(ending30)}}</td>
            </tr>
          </template>
        </v-data-table>
        <!-- 手机版 -->
        <!-- <v-row v-if="responsive =='mobile'" class="mx-0 fw-bold">
          <v-col cols="12" md="6" xs="6">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="6" xs="6">
            {{$t("portfolio.total")}}
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.startingValue")}}</span>
            <span class="font-12 float-right">${{formatNum(starting30)}}</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.roe")}}</span>
            <span class="font-12 float-right">{{starting30 == 0 ? "0.00" : ((ending30 - starting30) / starting30 * 100).toFixed(2)}}%</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.pl")}}</span>
            <span class="font-12 float-right">${{formatNum(pl30)}}</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.endingValue")}}</span>
            <span class="font-12 float-right">${{formatNum(ending30)}}</span>
          </v-col>
          <v-col cols="12" md="6" xs="6">
            <v-divider></v-divider>
          </v-col>
        </v-row> -->
      </v-col>
      <!-- 60days -->
      <v-col cols="12" class="mt-5" v-if="current == null || current == '60days'">
        <h5 class="text-center mb-5">{{$t("portfolio.60days")}}</h5>
        <v-data-table dense :headers="Headers" :items="responsive == 'PC' ? portfolioResult.day60 : item60" hide-default-footer disable-pagination>
          <template v-slot:item.currency="{ item }">
            {{item.symbol}}
          </template>
          <template v-slot:item.sValue="{ item }">
            ${{formatNum(item.starting.toFixed(2))}}
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</span>
          </template>
          <template v-slot:item.eValue="{ item }">
            <span class="font-12 text-right">${{formatNum(item.ending.toFixed(2))}}</span>
          </template>
          <template v-slot:body.append v-if="responsive =='PC'">
            <tr>
              <td colspan="5"></td>
            </tr>
            <tr class="fw-bold">
              <td class="font-12">{{$t("portfolio.total")}}</td>
              <td class="font-12 text-right">${{formatNum(starting60)}}</td>
              <td class="font-12 text-right">{{starting60 == 0 ? "0.00" : ((ending60 - starting60) / starting60 * 100).toFixed(2)}}%</td>
              <td class="font-12 text-right">${{formatNum(pl60)}}</td>
              <td class="font-12 text-right">${{formatNum(ending60)}}</td>
            </tr>
          </template>
        </v-data-table>
        <!-- <v-row v-if="responsive =='mobile'" class="flex-b mx-0 fw-bold">
          <v-col cols="12" md="6" xs="6">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="6" xs="6">
            {{$t("portfolio.total")}}
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.startingValue")}}</span>
            <span class="font-12 float-right">${{formatNum(starting60)}}</span>
          </v-col>
         <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.roe")}}</span>
            <span class="font-12 float-right">{{starting60 == 0 ? "0.00" : ((ending60 - starting60) / starting60 * 100).toFixed(2)}}%</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.pl")}}</span>
            <span class="font-12 float-right">${{formatNum(pl60)}}</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.endingValue")}}</span>
            <span class="font-12 float-right">${{formatNum(ending60)}}</span>
          </v-col>
          <v-col cols="12" md="6" xs="6">
            <v-divider></v-divider>
          </v-col>
        </v-row> -->
      </v-col>
      <!-- 1quarter-->
      <v-col cols="12" class="mt-5" v-if="current == null || current == '1quarter'">
        <h5 class="text-center mb-5">{{$t("portfolio.1quarter")}}</h5>
        <v-data-table dense :headers="Headers" :items="responsive == 'PC' ? portfolioResult.day90 : item90" hide-default-footer disable-pagination>
          <template v-slot:item.currency="{ item }">
            {{item.symbol}}
          </template>
          <template v-slot:item.sValue="{ item }">
            ${{formatNum(item.starting.toFixed(2))}}
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</span>
          </template>
          <template v-slot:item.eValue="{ item }">
            <span class="font-12 text-right">${{formatNum(item.ending.toFixed(2))}}</span>
          </template>
          <template v-slot:body.append v-if="responsive =='PC'">
            <tr>
              <td colspan="5"></td>
            </tr>
            <tr class="fw-bold">
              <td class="font-12">{{$t("portfolio.total")}}</td>
              <td class="font-12 text-right">${{formatNum(starting90)}}</td>
              <td class="font-12 text-right">{{starting60 == 0 ? "0.00" : ((ending90 - starting90) / starting90 * 100).toFixed(2)}}%</td>
              <td class="font-12 text-right">${{formatNum(pl90)}}</td>
              <td class="font-12 text-right">${{formatNum(ending90)}}</td>
            </tr>
          </template>
        </v-data-table>
        <!-- <v-row v-if="responsive =='mobile'" class="flex-b mx-0 fw-bold">
          <v-col cols="12" md="6" xs="6">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="6" xs="6">
            {{$t("portfolio.total")}}
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.startingValue")}}</span>
            <span class="font-12 float-right">${{formatNum(starting90)}}</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.roe")}}</span>
            <span class="font-12 float-right">{{starting90 == 0 ? "0.00" : ((ending90 - starting90) / starting90 * 100).toFixed(2)}}%</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.pl")}}</span>
            <span class="font-12 float-right">${{formatNum(pl90)}}</span>
          </v-col>
         <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.endingValue")}}</span>
            <span class="font-12 float-right">${{formatNum(ending90)}}</span>
          </v-col>
          <v-col cols="12" md="6" xs="6">
            <v-divider></v-divider>
          </v-col>
        </v-row> -->
      </v-col>
      <!-- 6months -->
      <v-col cols="12" class="mt-5" v-if="current == null || current == '6months'">
        <h5 class="text-center mb-5">{{$t("portfolio.6months")}}</h5>
        <v-data-table dense :headers="Headers" :items="responsive == 'PC' ? portfolioResult.day180 : item180" hide-default-footer disable-pagination>
          <template v-slot:item.currency="{ item }">
            {{item.symbol}}
          </template>
          <template v-slot:item.sValue="{ item }">
            ${{formatNum(item.starting.toFixed(2))}}
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</span>
          </template>
          <template v-slot:item.eValue="{ item }">
            <span class="font-12 text-right">${{formatNum(item.ending.toFixed(2))}}</span>
          </template>
          <template v-slot:body.append v-if="responsive =='PC'">
            <tr>
              <td colspan="5"></td>
            </tr>
            <tr class="fw-bold">
              <td class="font-12">{{$t("portfolio.total")}}</td>
              <td class="font-12 text-right">${{formatNum(starting180)}}</td>
              <td class="font-12 text-right">{{starting180 == 0 ? "0.00" : ((ending180 - starting180) / starting180 * 100).toFixed(2)}}%</td>
              <td class="font-12 text-right">${{formatNum(pl180)}}</td>
              <td class="font-12 text-right">${{formatNum(ending180)}}</td>
            </tr>
          </template>
        </v-data-table>
        <!-- <v-row v-if="responsive =='mobile'" class="flex-b mx-0 fw-bold">
          <v-col cols="12" md="6" xs="6">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="6" xs="6">
            {{$t("portfolio.total")}}
          </v-col>
         <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.startingValue")}}</span>
            <span class="font-12 float-right">${{formatNum(starting180)}}</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.roe")}}</span>
            <span class="font-12 float-right">{{starting180 == 0 ? "0.00" : ((ending180 - starting180) / starting180 * 100).toFixed(2)}}%</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.pl")}}</span>
            <span class="font-12 float-right">${{formatNum(pl180)}}</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.endingValue")}}</span>
            <span class="font-12 float-right">${{formatNum(ending180)}}</span>
          </v-col>
          <v-col cols="12" md="6" xs="6">
            <v-divider></v-divider>
          </v-col>
        </v-row> -->
      </v-col>
      <!-- 1year -->
      <v-col cols="12" class="mt-5" v-if="current == null || current == '1year'">
        <h5 class="text-center mb-5">{{$t("portfolio.1year")}}</h5>
        <v-data-table dense :headers="Headers" :items="responsive == 'PC' ? portfolioResult.day360 : item360" hide-default-footer disable-pagination>
          <template v-slot:item.currency="{ item }">
            {{item.symbol}}
          </template>
          <template v-slot:item.sValue="{ item }">
            ${{formatNum(item.starting.toFixed(2))}}
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</span>
          </template>
          <template v-slot:item.eValue="{ item }">
            <span class="font-12 text-right">${{formatNum(item.ending.toFixed(2))}}</span>
          </template>
          <template v-slot:body.append v-if="responsive =='PC'">
            <tr>
              <td colspan="5"></td>
            </tr>
            <tr class="fw-bold">
              <td class="font-12">{{$t("portfolio.total")}}</td>
              <td class="font-12 text-right">${{formatNum(starting360)}}</td>
              <td class="font-12 text-right">{{starting360 == 0 ? "0.00" : ((ending360 - starting360) / starting360 * 100).toFixed(2)}}%</td>
              <td class="font-12 text-right">${{formatNum(pl360)}}</td>
              <td class="font-12 text-right">${{formatNum(ending360)}}</td>
            </tr>
          </template>
        </v-data-table>
        <!-- <v-row v-if="responsive =='mobile'" class="flex-b mx-0 fw-bold">
          <v-col cols="12" md="6" xs="6">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="6" xs="6">
            {{$t("portfolio.total")}}
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.startingValue")}}</span>
            <span class="font-12 float-right">${{formatNum(starting360)}}</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.roe")}}</span>
            <span class="font-12 float-right">{{starting360 == 0 ? "0.00" : ((ending360 - starting360) / starting360 * 100).toFixed(2)}}%</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.pl")}}</span>
            <span class="font-12 float-right">${{formatNum(pl360)}}</span>
          </v-col>
          <v-col cols="12" class="pr-0">
            <span>{{$t("portfolio.endingValue")}}</span>
            <span class="font-12 float-right">${{formatNum(ending360)}}</span>
          </v-col>
          <v-col cols="12" md="6" xs="6">
            <v-divider></v-divider>
          </v-col>
        </v-row> -->
      </v-col>
      <!-- sinceInception -->
      <v-col cols="12" class="mt-5" v-if="current == null || current == 'sinceInception'">
        <h5 class="text-center mb-5">{{$t("portfolio.sinceInception")}}</h5>
        <v-data-table dense :headers="Headers"  :items="responsive == 'PC' ? portfolioResult.sinceInception : itemSinceInception" hide-default-footer disable-pagination>
          <template v-slot:item.currency="{ item }">
            {{item.symbol}}
          </template>
          <template v-slot:item.sValue="{ item }">
            ${{formatNum(item.starting.toFixed(2))}}
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</span>
          </template>
          <template v-slot:item.eValue="{ item }">
            <span class="font-12 text-right">${{formatNum(item.ending.toFixed(2))}}</span>
          </template>
          <template v-slot:body.append v-if="responsive =='PC'">
            <tr>
              <td colspan="5"></td>
            </tr>
            <tr class="fw-bold">
              <td class="font-12">{{$t("portfolio.total")}}</td>
              <td class="font-12 text-right">${{formatNum(startingSinceInception)}}</td>
              <td class="font-12 text-right">{{startingSinceInception == 0 ? "0.00" : ((endingSinceInception - startingSinceInception) / startingSinceInception * 100).toFixed(2)}}%</td>
              <td class="font-12 text-right">${{formatNum(plSinceInception)}}</td>
              <td class="font-12 text-right">${{formatNum(endingSinceInception)}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import config from '@/config.js';
import {
  mapGetters
} from "vuex";
// 引入导出Excel表格依赖
import FileSaver from "file-saver";
import XLSX from "xlsx";
import Bus from '@/components/bus/Bus';

  export default {
    data() {
      return {
        portfolioResult: [],
        loading: false,
        Headers: [{
            text: this.$t('portfolio.currency'),
            sortable: false,
            value: "currency"
          },
          {
            text: this.$t('portfolio.startingValue'),
            sortable: false,
            value: "sValue",
            align: 'end'
          },
          {
            text: this.$t('portfolio.roe'),
            sortable: false,
            value: "roe",
            align: 'end'
          },
          {
            text: this.$t('portfolio.pl'),
            sortable: false,
            value: "pl",
            align: 'end'
          },
          {
            text: this.$t('portfolio.endingValue'),
            sortable: false,
            value: "eValue",
            align: 'end'
          },
        ],
        selectList: [{
            text: this.$t("portfolio.30days"),
            value: "30days"
          },
          {
            text: this.$t("portfolio.60days"),
            value: "60days"
          },
          {
            text: this.$t("portfolio.1quarter"),
            value: "1quarter"
          },
          {
            text: this.$t("portfolio.6months"),
            value: "6months"
          },
          {
            text: this.$t("portfolio.1year"),
            value: "1year"
          },
          {
            text: this.$t("portfolio.sinceInception"),
            value: "sinceInception"
          },
        ],
        current: null,
        symbol:null,
        symbolItems:[],
        item30:[],
        item60:[],
        item90:[],
        item180:[],
        item360:[],
        itemSinceInception:[],
      }
    },
    computed: {
      ...mapGetters(['user', 'responsive']),
      starting30() {
        var total = 0;
        if (this.portfolioResult.day30 != null && this.portfolioResult.day30.length > 0) {
          for (var i = 0; i < this.portfolioResult.day30.length; i++) {
            total += this.portfolioResult.day30[i].starting;
          }
        }
        return total.toFixed(2);
      },
      pl30() {
        var total = 0;
        if (this.portfolioResult.day30 != null && this.portfolioResult.day30.length > 0) {
          for (var i = 0; i < this.portfolioResult.day30.length; i++) {
            total += this.portfolioResult.day30[i].pl;
          }
        }
        return total.toFixed(2);
      },
      ending30() {
        var total = 0;
        if (this.portfolioResult.day30 != null && this.portfolioResult.day30.length > 0) {
          for (var i = 0; i < this.portfolioResult.day30.length; i++) {
            total += this.portfolioResult.day30[i].ending;
          }
        }
        return total.toFixed(2);
      },
      starting60() {
        var total = 0;
        if (this.portfolioResult.day60 != null && this.portfolioResult.day60.length > 0) {
          for (var i = 0; i < this.portfolioResult.day60.length; i++) {
            total += this.portfolioResult.day60[i].starting;
          }
        }
        return total.toFixed(2);
      },
      pl60() {
        var total = 0;
        if (this.portfolioResult.day60 != null && this.portfolioResult.day60.length > 0) {
          for (var i = 0; i < this.portfolioResult.day60.length; i++) {
            total += this.portfolioResult.day60[i].pl;
          }
        }
        return total.toFixed(2);
      },
      ending60() {
        var total = 0;
        if (this.portfolioResult.day60 != null && this.portfolioResult.day60.length > 0) {
          for (var i = 0; i < this.portfolioResult.day60.length; i++) {
            total += this.portfolioResult.day60[i].ending;
          }
        }
        return total.toFixed(2);
      },
      starting90() {
        var total = 0;
        if (this.portfolioResult.day90 != null && this.portfolioResult.day90.length > 0) {
          for (var i = 0; i < this.portfolioResult.day90.length; i++) {
            total += this.portfolioResult.day90[i].starting;
          }
        }
        return total.toFixed(2);
      },
      pl90() {
        var total = 0;
        if (this.portfolioResult.day90 != null && this.portfolioResult.day90.length > 0) {
          for (var i = 0; i < this.portfolioResult.day90.length; i++) {
            total += this.portfolioResult.day90[i].pl;
          }
        }
        return total.toFixed(2);
      },
      ending90() {
        var total = 0;
        if (this.portfolioResult.day90 != null && this.portfolioResult.day90.length > 0) {
          for (var i = 0; i < this.portfolioResult.day90.length; i++) {
            total += this.portfolioResult.day90[i].ending;
          }
        }
        return total.toFixed(2);
      },
      starting180() {
        var total = 0;
        if (this.portfolioResult.day180 != null && this.portfolioResult.day180.length > 0) {
          for (var i = 0; i < this.portfolioResult.day180.length; i++) {
            total += this.portfolioResult.day180[i].starting;
          }
        }
        return total.toFixed(2);
      },
      pl180() {
        var total = 0;
        if (this.portfolioResult.day180 != null && this.portfolioResult.day180.length > 0) {
          for (var i = 0; i < this.portfolioResult.day180.length; i++) {
            total += this.portfolioResult.day180[i].pl;
          }
        }
        return total.toFixed(2);
      },
      ending180() {
        var total = 0;
        if (this.portfolioResult.day180 != null && this.portfolioResult.day180.length > 0) {
          for (var i = 0; i < this.portfolioResult.day180.length; i++) {
            total += this.portfolioResult.day180[i].ending;
          }
        }
        return total.toFixed(2);
      },
      starting360() {
        var total = 0;
        if (this.portfolioResult.day360 != null && this.portfolioResult.day360.length > 0) {
          for (var i = 0; i < this.portfolioResult.day360.length; i++) {
            total += this.portfolioResult.day360[i].starting;
          }
        }
        return total.toFixed(2);
      },
      pl360() {
        var total = 0;
        if (this.portfolioResult.day360 != null && this.portfolioResult.day360.length > 0) {
          for (var i = 0; i < this.portfolioResult.day360.length; i++) {
            total += this.portfolioResult.day360[i].pl;
          }
        }
        return total.toFixed(2);
      },
      ending360() {
        var total = 0;
        if (this.portfolioResult.day360 != null && this.portfolioResult.day360.length > 0) {
          for (var i = 0; i < this.portfolioResult.day360.length; i++) {
            total += this.portfolioResult.day360[i].ending;
          }
        }
        return total.toFixed(2);
      },
      startingSinceInception() {
        var total = 0;
        if (this.portfolioResult.sinceInception != null && this.portfolioResult.sinceInception.length > 0) {
          for (var i = 0; i < this.portfolioResult.sinceInception.length; i++) {
            total += this.portfolioResult.sinceInception[i].starting;
          }
        }
        return total.toFixed(2);
      },
      plSinceInception() {
        var total = 0;
        if (this.portfolioResult.sinceInception != null && this.portfolioResult.sinceInception.length > 0) {
          for (var i = 0; i < this.portfolioResult.sinceInception.length; i++) {
            total += this.portfolioResult.sinceInception[i].pl;
          }
        }
        return total.toFixed(2);
      },
      endingSinceInception() {
        var total = 0;
        if (this.portfolioResult.sinceInception != null && this.portfolioResult.sinceInception.length > 0) {
          for (var i = 0; i < this.portfolioResult.sinceInception.length; i++) {
            total += this.portfolioResult.sinceInception[i].ending;
          }
        }
        return total.toFixed(2);
      }
    },
    watch: {
      symbol(val){
        if(this.responsive == "mobile"){
          var temp30Arr = [];
          var temp60Arr = [];
          var temp90Arr = [];
          var temp180Arr = [];
          var temp360Arr = [];
          var tempSinceInceptionArr = [];
          for (let i = 0; i < this.portfolioResult.day30.length; i++) {
            const element = this.portfolioResult.day30[i];
            if(element.symbol == val){
              temp30Arr.push(element);
            }
          }
          for (let i = 0; i < this.portfolioResult.day60.length; i++) {
            const element = this.portfolioResult.day60[i];
            if(element.symbol == val){
              temp60Arr.push(element);
            }
          }
          for (let i = 0; i < this.portfolioResult.day90.length; i++) {
            const element = this.portfolioResult.day90[i];
            if(element.symbol == val){
              temp90Arr.push(element);
            }
          }
          for (let i = 0; i < this.portfolioResult.day180.length; i++) {
            const element = this.portfolioResult.day180[i];
            if(element.symbol == val){
              temp180Arr.push(element);
            }
          }
          for (let i = 0; i < this.portfolioResult.day360.length; i++) {
            const element = this.portfolioResult.day360[i];
            if(element.symbol == val){
              temp360Arr.push(element);
            }
          }
          for (let i = 0; i < this.portfolioResult.sinceInception.length; i++) {
            const element = this.portfolioResult.sinceInception[i];
            if(element.symbol == val){
              tempSinceInceptionArr.push(element);
            }
          }
          this.item30 = temp30Arr;
          this.item60 = temp60Arr;
          this.item90 = temp90Arr;
          this.item180 = temp180Arr;
          this.item360 = temp360Arr;
          this.itemSinceInception = tempSinceInceptionArr;
        }
      }
    },
    mounted() {
      this.getResult();
    },
    created() {

    },
    methods: {
      getResult() {
        this.loading = true;
        let api = config.baseUrl + '/signal/public/getPortfolioResult';
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200) {
            this.portfolioResult = data.result;
            var tempSymbolArr = [];
            for(var i = 0 ; i < data.result.sinceInception.length ; i++){
              if(tempSymbolArr.indexOf(data.result.sinceInception[i].symbol) == -1){
                tempSymbolArr.push(data.result.sinceInception[i].symbol);
              }
              this.symbolItems = tempSymbolArr;
              this.symbol = this.symbolItems[0];
            }
            this.loading = false;
            var roeArr = [];
            roeArr.push(Number(((this.ending30 - this.starting30) / this.starting30 * 100).toFixed(2)))
            roeArr.push(Number(((this.ending60 - this.starting60) / this.starting60 * 100).toFixed(2)))
            roeArr.push(Number(((this.ending90 - this.starting90) / this.starting90 * 100).toFixed(2)))
            roeArr.push(Number(((this.ending180 - this.starting180) / this.starting180 * 100).toFixed(2)))
            roeArr.push(Number(((this.ending360 - this.starting360) / this.starting360 * 100).toFixed(2)))
            roeArr.push(Number(((this.endingSinceInception - this.startingSinceInception) / this.startingSinceInception * 100).toFixed(2)))
            let date = ["30 Days","60 Days","1 Quarter","6 Months","1 Year","Since Inception"];
            let portfolioRoeHistoryChart = [
              { 'date': date },
              { 'data': roeArr }
            ];
            this.$store.dispatch('portfolioRoeHistoryChartHandler', portfolioRoeHistoryChart);
            Bus.$emit('portfolioRoeHistory', roeArr);
          } else {
            this.loading = false;
          }
        });
      },
      //定义导出Excel表格事件
      exportExcel() {
        /* 从表生成工作簿对象 */
        var today = new Date()
        var wb = XLSX.utils.table_to_book(document.querySelector("#Portfolio"));
        /* 获取二进制字符串作为输出 */
        var wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          FileSaver.saveAs(
            //Blob 对象表示一个不可变、原始数据的类文件对象。
            //Blob 表示的不一定是JavaScript原生格式的数据。
            //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
            //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
            new Blob([wbout], {
              type: "application/octet-stream"
            }),
            //设置导出文件名称
            "Portfolio" + this.common.dateFormat(today) + ".xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        return wbout;
      },
      // 四舍五入 保留小数
      getFloat(num, n) {
        n = n ? parseInt(n) : 0;
        if (n <= 0) {
          return Math.round(num);
        }
        num = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); //四舍五入
        num = Number(num).toFixed(n); //补足位数
        return num;
      },
      // 首字母大写
      toFirstLetterUpperCase(message) {
        if (message) {
          return message.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
        } else {
          return null;
        }
      },
      dateFormat(time) {
        var date = new Date(time);
        var year = date.getFullYear();
        /* 在日期格式中，月份是从0开始的，因此要加0
         * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
         * */
        var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        // 拼接
        return (day + "/" + month + "/" + year);
      },
      // 货币格式化
      formatNum(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }
</script>
