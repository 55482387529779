var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-row',{staticClass:"pa-12",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table-heading, table-thead, table-row-divider@7"}})],1)],1):_vm._e(),(!_vm.loading)?_c('v-row',{staticClass:"pa-12 pt-3",attrs:{"dense":""}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"6","xs":"2"}},[_c('span',[_vm._v(_vm._s(_vm.$t('portfolio.date'))+": 07/17/2019 - 10/16/2020")])]),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"2","xs":"2"}},[_c('v-select',{staticClass:"fs-14",attrs:{"items":_vm.selectList,"item-text":"text","item-value":"value","clearable":"","hide-details":"","dense":"","label":_vm.$t('portfolio.interval')},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1),(_vm.responsive =='mobile')?_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"2","xs":"2"}},[_c('v-select',{staticClass:"fs-14",attrs:{"items":_vm.symbolItems,"hide-details":"","dense":"","label":_vm.$t('portfolio.currency')},model:{value:(_vm.symbol),callback:function ($$v) {_vm.symbol=$$v},expression:"symbol"}})],1):_vm._e(),(_vm.current == null || _vm.current == '30days')?_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("portfolio.30days")))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.Headers,"items":_vm.responsive == 'PC' ? _vm.portfolioResult.day30 : _vm.item30,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" ")]}},{key:"item.sValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.formatNum(item.starting.toFixed(2)))+" ")]}},{key:"item.roe",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.roe < 0 ? 'red--text' : ''},[_vm._v(_vm._s((item.roe * 100).toFixed(2))+"%")])]}},{key:"item.pl",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.pl < 0 ? 'red--text' : ''},[_vm._v("$"+_vm._s(_vm.formatNum(item.pl.toFixed(2))))])]}},{key:"item.eValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(item.ending.toFixed(2))))])]}},(_vm.responsive =='PC')?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"5"}})]),_c('tr',{staticClass:"fw-bold"},[_c('td',{staticClass:"font-12"},[_vm._v(_vm._s(_vm.$t("portfolio.total")))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.starting30)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v(_vm._s(_vm.starting30 == 0 ? "0.00" : ((_vm.ending30 - _vm.starting30) / _vm.starting30 * 100).toFixed(2))+"%")]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.pl30)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.ending30)))])])]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.current == null || _vm.current == '60days')?_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-center mb-5"},[_vm._v(_vm._s(_vm.$t("portfolio.60days")))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.Headers,"items":_vm.responsive == 'PC' ? _vm.portfolioResult.day60 : _vm.item60,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" ")]}},{key:"item.sValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.formatNum(item.starting.toFixed(2)))+" ")]}},{key:"item.roe",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.roe < 0 ? 'red--text' : ''},[_vm._v(_vm._s((item.roe * 100).toFixed(2))+"%")])]}},{key:"item.pl",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.pl < 0 ? 'red--text' : ''},[_vm._v("$"+_vm._s(_vm.formatNum(item.pl.toFixed(2))))])]}},{key:"item.eValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(item.ending.toFixed(2))))])]}},(_vm.responsive =='PC')?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"5"}})]),_c('tr',{staticClass:"fw-bold"},[_c('td',{staticClass:"font-12"},[_vm._v(_vm._s(_vm.$t("portfolio.total")))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.starting60)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v(_vm._s(_vm.starting60 == 0 ? "0.00" : ((_vm.ending60 - _vm.starting60) / _vm.starting60 * 100).toFixed(2))+"%")]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.pl60)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.ending60)))])])]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.current == null || _vm.current == '1quarter')?_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-center mb-5"},[_vm._v(_vm._s(_vm.$t("portfolio.1quarter")))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.Headers,"items":_vm.responsive == 'PC' ? _vm.portfolioResult.day90 : _vm.item90,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" ")]}},{key:"item.sValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.formatNum(item.starting.toFixed(2)))+" ")]}},{key:"item.roe",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.roe < 0 ? 'red--text' : ''},[_vm._v(_vm._s((item.roe * 100).toFixed(2))+"%")])]}},{key:"item.pl",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.pl < 0 ? 'red--text' : ''},[_vm._v("$"+_vm._s(_vm.formatNum(item.pl.toFixed(2))))])]}},{key:"item.eValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(item.ending.toFixed(2))))])]}},(_vm.responsive =='PC')?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"5"}})]),_c('tr',{staticClass:"fw-bold"},[_c('td',{staticClass:"font-12"},[_vm._v(_vm._s(_vm.$t("portfolio.total")))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.starting90)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v(_vm._s(_vm.starting60 == 0 ? "0.00" : ((_vm.ending90 - _vm.starting90) / _vm.starting90 * 100).toFixed(2))+"%")]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.pl90)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.ending90)))])])]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.current == null || _vm.current == '6months')?_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-center mb-5"},[_vm._v(_vm._s(_vm.$t("portfolio.6months")))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.Headers,"items":_vm.responsive == 'PC' ? _vm.portfolioResult.day180 : _vm.item180,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" ")]}},{key:"item.sValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.formatNum(item.starting.toFixed(2)))+" ")]}},{key:"item.roe",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.roe < 0 ? 'red--text' : ''},[_vm._v(_vm._s((item.roe * 100).toFixed(2))+"%")])]}},{key:"item.pl",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.pl < 0 ? 'red--text' : ''},[_vm._v("$"+_vm._s(_vm.formatNum(item.pl.toFixed(2))))])]}},{key:"item.eValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(item.ending.toFixed(2))))])]}},(_vm.responsive =='PC')?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"5"}})]),_c('tr',{staticClass:"fw-bold"},[_c('td',{staticClass:"font-12"},[_vm._v(_vm._s(_vm.$t("portfolio.total")))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.starting180)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v(_vm._s(_vm.starting180 == 0 ? "0.00" : ((_vm.ending180 - _vm.starting180) / _vm.starting180 * 100).toFixed(2))+"%")]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.pl180)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.ending180)))])])]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.current == null || _vm.current == '1year')?_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-center mb-5"},[_vm._v(_vm._s(_vm.$t("portfolio.1year")))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.Headers,"items":_vm.responsive == 'PC' ? _vm.portfolioResult.day360 : _vm.item360,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" ")]}},{key:"item.sValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.formatNum(item.starting.toFixed(2)))+" ")]}},{key:"item.roe",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.roe < 0 ? 'red--text' : ''},[_vm._v(_vm._s((item.roe * 100).toFixed(2))+"%")])]}},{key:"item.pl",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.pl < 0 ? 'red--text' : ''},[_vm._v("$"+_vm._s(_vm.formatNum(item.pl.toFixed(2))))])]}},{key:"item.eValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(item.ending.toFixed(2))))])]}},(_vm.responsive =='PC')?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"5"}})]),_c('tr',{staticClass:"fw-bold"},[_c('td',{staticClass:"font-12"},[_vm._v(_vm._s(_vm.$t("portfolio.total")))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.starting360)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v(_vm._s(_vm.starting360 == 0 ? "0.00" : ((_vm.ending360 - _vm.starting360) / _vm.starting360 * 100).toFixed(2))+"%")]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.pl360)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.ending360)))])])]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.current == null || _vm.current == 'sinceInception')?_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-center mb-5"},[_vm._v(_vm._s(_vm.$t("portfolio.sinceInception")))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.Headers,"items":_vm.responsive == 'PC' ? _vm.portfolioResult.sinceInception : _vm.itemSinceInception,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" ")]}},{key:"item.sValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.formatNum(item.starting.toFixed(2)))+" ")]}},{key:"item.roe",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.roe < 0 ? 'red--text' : ''},[_vm._v(_vm._s((item.roe * 100).toFixed(2))+"%")])]}},{key:"item.pl",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right",class:item.pl < 0 ? 'red--text' : ''},[_vm._v("$"+_vm._s(_vm.formatNum(item.pl.toFixed(2))))])]}},{key:"item.eValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(item.ending.toFixed(2))))])]}},(_vm.responsive =='PC')?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"5"}})]),_c('tr',{staticClass:"fw-bold"},[_c('td',{staticClass:"font-12"},[_vm._v(_vm._s(_vm.$t("portfolio.total")))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.startingSinceInception)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v(_vm._s(_vm.startingSinceInception == 0 ? "0.00" : ((_vm.endingSinceInception - _vm.startingSinceInception) / _vm.startingSinceInception * 100).toFixed(2))+"%")]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.plSinceInception)))]),_c('td',{staticClass:"font-12 text-right"},[_vm._v("$"+_vm._s(_vm.formatNum(_vm.endingSinceInception)))])])]},proxy:true}:null],null,true)})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }